.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --color-danger: #FF4D4F;
  --color-success: #01B81A;
  --color-warning: #FA8B0C;
  --color-info: #85c7f9;
  --color-dark: #282c34;
  --color-darker: #3e4148;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.font-link {
  font-family: 'Jost', sans-serif;
}



.container-sidebar {
  display: flex;
  height: 100%;
  overflow: scroll initial;

}


.sidebar-gmao {
  width: 200px !important;
  min-width: 24px !important;
}

.sidebar-small{
  background-color: var(--color-dark) !important;
  color: white !important;
  font-weight: 500;
  font-size: larger;
}


.sidebar-small-header{
  background-color: whitesmoke;
  color: var(--color-dark) !important;
  font-weight: 600;
  font-size: larger;
}


.header-card-site :hover{
  cursor: pointer;
}


.align-left {
  display: flex !important;
  justify-content: left !important;
}


.align-right {
  display: flex !important;
  justify-content: right !important;
}



.active li {
  background-color: var(--color-darker) !important;
}



nav ul li:hover {
  /* color: lightgray  !important; */
  background-color: var(--color-darker) !important;

}


.bg-infoB {
  background-color: var(--color-info) !important;
}

.btn-infoB {
  background-color: var(--color-info) !important;

}

.card-footer-button :hover {
  background-color: var(--color-info) !important;
  border-radius: var(--bs-card-inner-border-radius);
  /* padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x); */
  color: var(--bs-card-cap-color);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}




.navLeftMenu a {
  font-family: 'Roboto', sans-serif;
  color: black !important;
  text-decoration: none;
  margin: 3px;
}

.navLeftMenu div {

  display: flex;
  align-items: center;
  justify-content: center;

}




.container-login-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}


.viewport-height {
  height: 75vh;
}

.waiting {
  background-color: gray;
}

.bt-eye-password {
  background-color: transparent !important;
  color: #212529 !important;
  border-color: #ced4da !important;
}


.link-password-forgot {
  color: var(--bs-primary) !important;
}

.centerStack {
  display: flex;
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  justify-content: center;
}

.project-sort-nav {
  margin: 0px auto;
}

.project-sort-nav nav {
  background: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 6px 20px;
}

.project-sort-nav nav ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  /* justify-content: space-between; */
}

.project-sort-nav nav ul li {
  padding-right: 12px !important;
  margin-right: 11px !important;
  border-right: 1px solid rgb(241, 242, 246) !important;
}


.project-sort-nav nav ul li:last-child {
  padding-right: 0px !important;
  margin-right: 0px !important;
  border-right: 0px none !important;
}

.content:hover {
  transform: scale(1.1);
  transform-origin: bottom;
}


.noBorder {
  border: none !important
}


sup {
  top: -.5em;
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.circle-success {
  background: var(--color-success);
  width: 10px;
  height: 10px;
  border-radius: 100%;
}




.circle-danger {
  background: var(--color-danger);
  width: 10px;
  height: 10px;
  border-radius: 100%;
}


.icon-bt {
  height: 10px;
  margin-bottom: 5px !important;
}

.bt-actif {
  color: var(--color-success) !important;
}

.bt-inactif {
  color: var(--bs-secondary) !important;
}

.filter-actif {
  color: var(--bs-primary);
}

.icon-bt:hover {
  color: var(--bs-primary);
  cursor: pointer;
}



.card-clientsite {
  border-width: 3px !important;
  border-color: #01B81A !important;
}

.bt-clientsite {

  font-size: 15px !important;
  font-weight: 600 !important;

  /* height: 38px!important; */
  border-radius: 6px !important;
  box-shadow: rgba(116, 116, 116, 0.02) 0px 3px 5px !important;
  margin: 5px !important;
  background: rgb(255, 255, 255) !important;

  color: rgb(64, 64, 64) !important;
  border: 1px solid rgb(241, 242, 246) !important;
  width: 100%;
}

.bt-clientsite:hover {
  background-color: var(--bs-primary) !important;
  color: rgb(255, 255, 255) !important;
}

.hoverclassic:hover {
  cursor: default;
}


.li-actif {
  color: cornflowerblue !important;
}

.li-inactif:hover {
  color: cornflowerblue !important;
  cursor: pointer;
  background-color: white !important;

}

.li-actif:hover {
  color: cornflowerblue !important;
  cursor: pointer;
  background-color: white !important;
}




.img-bt-filter {
  height: 20px;
  margin-bottom: 3px;
}

.button-periode {
  background-color: white !important;
}






.table-basic-row-group {
  background-color: whitesmoke !important;
  border-radius: 10px 10px 10px 10px !important;
  text-align: center !important;
  padding: 5px;
}


.table-presta th {
  padding: 0px !important;
  border: transparent;
  font-size: 14px !important;
  font-weight: 400;
  text-align: start;
}



.table-presta th:first-child div {
  border: 1px solid #ccc;
  border-radius: 10px 0 0 10px;
  border-right: transparent;
  background-color: #f8f9fb;
  padding: 10px 5px;
}





.table-presta th div {
  border: 1px solid #ccc;
  border-right: transparent;
  border-left: transparent;
  background-color: #f8f9fb !important;
  padding: 10px 5px;
}

.table-presta th:last-child div {
  border: 1px solid #ccc;
  border-radius: 0 10px 10px 0;
  border-left: transparent;
  background-color: #f8f9fb !important;
  padding: 10px 5px;
}

.table-presta td {
  text-align: start;
}


.table-presta h1 {
  font-size: 15px;
  font-weight: 600;
  color: #0a0a0a;
  line-height: 24px;
  /* cursor: pointer; */
}

.table-presta tr:hover {
  background-color: whitesmoke;
  /* cursor: pointer; */
  cursor: default;

}

.table-presta th:hover {
  cursor: default;
}


.row-height {
  height: 3rem !important;
  overflow: hidden;
  white-space: nowrap;
}

.table-presta-row-selected {
  background-color: whitesmoke;
}


.table-presta button {
  border: none !important;
  color: #212529;
  background-color: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
}


.table-presta button:hover {
  border: none !important;
  color: #ccc !important;
  background-color: transparent !important;
}

.table-presta svg {
  margin-left: 4px;
}


.title {
  font-size: x-large;
  font-weight: bolder;
}

.subtitle {
  font-size: large;
  font-weight: bolder;

}

.background {
  background-color: #f4f5f7;
}

.container-table {
  background-color: white;
  padding-top: 10px;
  border-radius: 10px;
}


.popover-liste {
  background-color: white !important;
}



.card-document {
  background-color: white !important;
}

.document-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #0a0a0a !important;
  text-align: start;
}


.document-size {
  font-size: 10px !important;
  color: #0a0a0a !important;
  text-align: start;
}


.document-links {
  text-align: start;
}



.document-links a {
  text-decoration: none;
  font-size: 13px;
  color: var(--bs-primary);
  text-align: start;
  margin-right: 10px;
}


.badge-bg-success-nowrap {
  background-color: var(--color-success) !important;
  font-weight: 400 !important;
}


.badge-bg-warning-nowrap {
  background-color: var(--color-warning) !important;
  font-weight: 400 !important;
}

.badge-bg-primary-nowrap {
  background-color: var(--bs-primary) !important;
  font-weight: 400 !important;
}

.badge-bg-secondary-nowrap {
  background-color: var(--bs-secondary) !important;
  font-weight: 400 !important;
}


.badge-bg-danger-nowrap {
  background-color: var(--color-danger) !important;
  font-weight: 400 !important;
}


.badge-bg-info-nowrap {
  background-color: var(--color-info) !important;
  font-weight: 400 !important;
}




.badge-bg-success {
  background-color: var(--color-success) !important;
  display: inline-block;
  width: 100px !important;
  font-weight: 400 !important;
}


.badge-bg-warning {
  background-color: var(--color-warning) !important;
  display: inline-block;
  width: 100px !important;
  font-weight: 400 !important;
}

.badge-bg-primary {
  background-color: var(--bs-primary) !important;
  display: inline-block;
  width: 100px !important;
  font-weight: 400 !important;
}

.badge-bg-secondary {
  background-color: var(--bs-secondary) !important;
  display: inline-block;
  width: 100px !important;
  font-weight: 400 !important;
}

.badge-bg-danger {
  background-color: var(--color-danger) !important;
  display: inline-block;
  width: 100px !important;
  font-weight: 400 !important;
}

.modal-90w {
  margin: 5% !important;
  /* width: 80%; */
  max-width: none !important;
}

.popover-filters div {
  margin: 10px !important;
}